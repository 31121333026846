
.note__video {
    position: absolute;
    left: 50%;
    width: 100%;
    margin-top: 225px;
    height: 450px;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.gradient {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 450px;
    object-fit: cover;
    background-image: linear-gradient(#0f0808e8,#070202fd, #000000fd);
}

.note__h1 {
    color: #ffffffb6;
    line-height: 80px;
    text-align: left;
    margin-top: 130px;
    margin-left: 80px;
}

.note__p {
    color: white;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    margin-left: 80px;
}

.note__link{
    font-size: 20px;
    color: #09b179;
    text-align: left;
    margin-left: 80px;
    margin-top: 20px;
}

@media screen and (max-width: 500px) {
    .note__h1, .note__p, .note__link {
        margin-left: 30px;
    }

    .note__h1{
        line-height: 40px;
        margin-bottom: 50px;
    }
}