@import url('https://fonts.googleapis.com/css?family=Montserrat');

.main {
  display: flex;
  flex-direction: column;
}
.main__text {
  text-align: center;
  position: absolute;
  max-width: 80%;
  top: 10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

/* font (welcome to)*/
.main__text h1 {
  margin: 70px 0 0 0;
  font-size: 75px;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: #552a3c;
}

.main__text p {
  margin-top: 10px;
  font-size: 22px;
  color: #1d0f15;
}

/* particles */
#tsparticles {
  position: relative;
  top: 60px;
  left: 0;
  z-index: -1;
  height: 80vh;
}



.title {
  font-family: "Montserrat";
  text-align: center;
  color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  letter-spacing: 2px;
}

.title h1 {
  background-image: url(https://media.giphy.com/media/26BROrSHlmyzzHf3i/giphy.gif);
  background-size: cover;
  color: transparent;
  background-clip: unset;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  text-transform: uppercase;
  font-size: 90px;
  line-height: .75;
  margin: 50px 0 50px 0;
}

@media only screen and (max-width : 800px) {
  .main__text p{
    font-size: 18px;
  }

  .main__text h1 {
    font-size: 50px;
  }

  .title h1 {
    font-size: 65px;
  }
}

@media screen and (max-width: 1080px) and (min-width: 800px){
  .main__text p {
    font-size: 20px;
  }

  .main__text h1 {
    font-size: 60px;
  }

  .title h1 {
    font-size: 75px;
  }
}

@media only screen and (max-width: 500px) {
  .main__desc{
    visibility: hidden;
  }

  .main__text h1 {
    margin-top: 100px;
  }

  .title h1 {
    margin-top: 40px;
  }

  #tsparticles{
    max-height: 70vh;
  }
}